
import { defineComponent, ref, onMounted } from "vue";
import ChannelDatatable from "@/components/kt-datatable/ChannelDataTable.vue";
// import emailChannelConfiguration from "@/views/communicationService/emailChannelConfiguration.vue"
// import smsChannelConfiguration from "@/views/communicationService/smsChannelConfiguration.vue"
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { CONFIGURE_CHANNEL } from "@/helper/globalUrl"
import { ElLoading } from 'element-plus';
import { ElNotification } from "element-plus";
import { useStore } from "vuex";
import Globals from "@/views/common/Globals.vue";

export default defineComponent({
  name: "Channel",
  components: {
    ChannelDatatable,
    // emailChannelConfiguration,
    // smsChannelConfiguration
  },
  mixins: [Globals],
  data() {
    return {
      toggleFlag: true,
      configureData: [] as any,
      channelStatus: {
        "INS": {
          toggleSMS: false,
          toggleEmail: false,
          toggleWhatsapp: false,
        }, 
        "FD": {
          toggleSMS: false,
          toggleEmail: false,
          toggleWhatsapp: false,
        }, 
        "PMS": {
          toggleSMS: false,
          toggleEmail: false,
          toggleWhatsapp: false,
        }
      },
      // communicationChannel:{},
      is1sbEnable: {},
      // emailCheck:false,
      // smsCheck:false,
      // whatsappCheck:false,
      // channelData: [
      //   {

      //       "mode":"Email",
      //       "gateway":false,
      //       "configuration":"Configure",

      //   },
      //   {

      //       "mode":"SMS",
      //       "gateway":true,
      //       "configuration":"Configure",

      //   },
      //   {

      //       "mode":"Whatsapp",
      //       "gateway":false,
      //       "configuration":"In Progress",

      //   }
      // ],
      channelData: [] as any
    };
  },

  mounted() {
    const store = useStore();
    this.getConfigurations()
  },
  methods: {
    replaceApplicationCaller(applicationCaller){
      if (applicationCaller == "INS"){
        return "Insurance"
      }else if (applicationCaller == "FD"){
        return "Wealth FD"
      }else{
        return "Wealth PMS"
      }
    },
    //setLocal  Storage
    setLocalStorage(){
      let json = {
        "FD":{
          email: this.channelStatus['FD'].toggleEmail,
          sms: this.channelStatus['FD'].toggleSMS,
          whatsapp: this.channelStatus['FD'].toggleWhatsapp
        }, 
        "PMS": {
          email: this.channelStatus['PMS'].toggleEmail,
          sms: this.channelStatus['PMS'].toggleSMS,
          whatsapp: this.channelStatus['PMS'].toggleWhatsapp
        },
        "INS": {
          email: this.channelStatus['INS'].toggleEmail,
          sms: this.channelStatus['INS'].toggleSMS,
          whatsapp: this.channelStatus['INS'].toggleWhatsapp
        }
          
        }
        console.log("json for status from channel-->>", json)
        localStorage.setItem("channel", JSON.stringify(json));
    },
    //Enable Disable Mode
    enableMode(event, id) {

      const data: any = {
        isEnabled: event.target.checked
      }
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.patch(CONFIGURE_CHANNEL + this.globalData.organizationId + '/' + id
        , data).then((data) => {
          loading.close();
          this.setLocalStorage();
          ElNotification({
            title: "Success",
            message: "Configuration Updated",
            type: "success",
            duration: 2000,
            position: "top-right",
          });


        })
        .catch((error) => {
          loading.close();
          console.log("Error in Patch API", error);

        });

    },
    //     changeCheckBoxValue(mode,checkEvent,toggleEvent, formData)
    //     {  
    //       console.log("Toggel Events",toggleEvent)
    //       if(typeof(checkEvent)=="object")
    //       {
    //         formData.isEnabled=checkEvent.target.checked
    //         formData.is1SBEnabled=toggleEvent
    //         if(mode=="Email")
    //         this.emailCheck=checkEvent.target.checked
    //         else if(mode=="SMS")
    //         this.smsCheck=checkEvent.target.checked
    //       }
    //       else if(typeof(toggleEvent)=="object")
    //       {
    //       formData.isEnabled=checkEvent
    //         formData.is1SBEnabled=toggleEvent.target.checked
    //         if(mode=="Email")
    //         this.toggleEmail=toggleEvent.target.checked
    //         else if(mode=="SMS")
    //         this.toggleSMS=toggleEvent.target.checked
    //       }
    //       formData.distributorId=this.globalData.organizationId
    //       formData.configuration={}
    //       if(mode=="Email" && 'email' in this.configureData && this.configureData.email)
    //       {
    //         formData.communicationChannel="email"
    //         if ('email' in this.configureData && this.configureData.email){
    //           formData.configuration.host=this.configureData.email.host
    //           formData.configuration.port=this.configureData.email.port
    //           formData.configuration.password=this.configureData.email.password
    //           formData.configuration.username=this.configureData.email.username
    //         }
    //       }
    //       else if(mode=="SMS")
    //       {
    //         formData.communicationChannel="sms"
    //         if ('sms' in this.configureData && this.configureData.sms){
    //           formData.configuration.api=this.configureData.sms.api
    //           formData.configuration.From=this.configureData.sms.From
    //           formData.configuration.apiKey=this.configureData.sms.apiKey
    //           formData.configuration.postUrl=this.configureData.sms.postUrl
    //         }
    //       }
    //       if(Object.keys(this.configureData).length!=0)
    //       {
    //         const loading = ElLoading.service({
    //           lock: true,
    //           text: "Loading",
    //           background: "rgba(0, 0, 0, 0.7)",
    //         });
    //           ApiService.patch(CONFIGURE_CHANNEL
    //           ,formData).then((data) => {
    //             console.log("Response data ",data);
    //             loading.close();
    //           })
    //           .catch((error) => {
    //             console.log(error);
    //             loading.close();
    //           });
    //           loading.close();
    //       }
    //       else
    //       {
    //         const loading = ElLoading.service({
    //           lock: true,
    //           text: "Loading",
    //           background: "rgba(0, 0, 0, 0.7)",
    //         });
    //           ApiService.post(CONFIGURE_CHANNEL,formData).then((data) => {
    //             console.log("Response data",data);
    //             loading.close();
    //           })
    //           .catch((error) => {
    //             console.log(error);
    //             loading.close();
    //           });
    //           loading.close();
    //       }
    //       let json = {
    //         email:this.emailCheck,
    //         sms: this.smsCheck,
    //         whatsapp:false
    //       }
    // localStorage.setItem("channel",JSON.stringify(json));
    //     },
    //     passChannelIdToEmailChannelConfigurationModal(channelId)
    //     {
    //      (this.$refs["passConfigureDataToEmailConfigureModal"] as any).callConfigureModal(this.configureData,channelId);
    //     },
    //     passChannelIdTosmsChannelConfigurationModal(channelId)
    //     {
    //      (this.$refs["passConfigureDataTosmsConfigureModal"] as any).callConfigureModal(this.configureData,channelId);
    //     },
    getConfigurations() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(CONFIGURE_CHANNEL + this.globalData.organizationId).then((data) => {
        loading.close();
        // this.communicationChannel=JSON.parse(JSON.stringify(data.data.data.communicationChannels))
        //  this.is1sbEnable=JSON.parse(JSON.stringify(data.data.data.is1SBEnabled))

        this.channelData = data.data;
        console.log("channel data-->>", this.channelData)
        this.channelData.map((item) => {
          if (item.communicationMode == "email") {
            this.channelStatus[item.applicationCaller].toggleEmail = item.isEnabled;
          }
          if (item.communicationMode == "sms") {
            this.channelStatus[item.applicationCaller].toggleSMS = item.isEnabled;
          }
          if (item.communicationMode == "whatsapp") {
            this.channelStatus[item.applicationCaller].toggleWhatsapp = item.isEnabled;
          }
        })

        this.setLocalStorage();
      })
        .catch((error) => {
          console.log("Error while fetching ", error);
          loading.close();
        });
    }
  },

  setup() {
    const store = useStore();
    // const formData = ref({
    // distributorId: "",
    // communicationChannel: "",
    // configuration: {},
    // isEnabled: false,
    // is1SBEnabled: false,
    // })
    const tableHeader = ref([
      {
        name: "Communication Mode",
        key: "communicationMode",
        sortable: true,
      },
      {
        name: "Gateway Name",
        key: "applicationCaller",
        sortable: true,
      },
      {
        name: "Enable Mode",
        key: "isEnabled",

      },
      // {
      //   name: "Configuration",
      //   key: "configuration",
      //   sortable: true,
      // },
    ]);
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Client Communication", ["Setting"]);
    });
    return {
      tableHeader
      // formData,
    };
  },
});
