import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body pt-0" }
const _hoisted_3 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_4 = { class: "text-dark text-hover-primary" }
const _hoisted_5 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_6 = { class: "text-dark text-hover-primary" }
const _hoisted_7 = { class: "form-check form-switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChannelDatatable = _resolveComponent("ChannelDatatable")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_ChannelDatatable, {
          "table-data": this.channelData,
          "table-header": _ctx.tableHeader,
          "enable-items-per-page-dropdown": false,
          disablePagination: true
        }, {
          "cell-communicationMode": _withCtx(({ row: channel }) => [
            _createVNode("div", _hoisted_3, [
              _createCommentVNode(" <input v-if=\"channel.mode=='Email'\" class=\"form-check-input me-3 border border-secondary\"\n                  type=\"checkbox\"\n                  :value=\"ENABLE\"\n                  v-model=\"this.emailCheck\"\n                  @change=\"changeCheckBoxValue(channel.mode,$event,this.toggleEmail, formData)\"/>\n\n            <input v-if=\"channel.mode=='SMS'\" class=\"form-check-input me-3 border border-secondary\"\n                  type=\"checkbox\"\n                  :value=\"ENABLE\"\n                  v-model=\"this.smsCheck\"\n                  @change=\"changeCheckBoxValue(channel.mode,$event,this.toggleSMS, formData)\"/>\n            \n            <input v-if=\"channel.mode=='Whatsapp'\" class=\"form-check-input me-3 border border-secondary\"\n                  type=\"checkbox\"\n                  :value=\"ENABLE\"\n                  v-model=\"this.whatsappCheck\"\n                  @change=\"changeCheckBoxValue(channel.mode,$event, this.toggleWhatsapp, formData)\"/> "),
              _createVNode("a", _hoisted_4, _toDisplayString(channel.communicationMode.toUpperCase()), 1 /* TEXT */)
            ])
          ]),
          "cell-applicationCaller": _withCtx(({ row: channel }) => [
            _createVNode("div", _hoisted_5, [
              _createVNode("span", _hoisted_6, _toDisplayString(_ctx.replaceApplicationCaller(channel.applicationCaller)), 1 /* TEXT */)
            ])
          ]),
          "cell-isEnabled": _withCtx(({ row: channel }) => [
            _createVNode("div", _hoisted_7, [
              (channel.communicationMode == 'email')
                ? _withDirectives((_openBlock(), _createBlock("input", {
                    key: 0,
                    class: "form-check-input",
                    type: "checkbox",
                    id: channel.id,
                    "onUpdate:modelValue": ($event: any) => (_ctx.channelStatus[channel.applicationCaller].toggleEmail = $event),
                    onChange: ($event: any) => (_ctx.enableMode($event, channel.id))
                  }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["id", "onUpdate:modelValue", "onChange"])), [
                    [_vModelCheckbox, _ctx.channelStatus[channel.applicationCaller].toggleEmail]
                  ])
                : _createCommentVNode("v-if", true),
              (channel.communicationMode == 'sms')
                ? _withDirectives((_openBlock(), _createBlock("input", {
                    key: 1,
                    class: "form-check-input",
                    type: "checkbox",
                    id: channel.id,
                    "onUpdate:modelValue": ($event: any) => (_ctx.channelStatus[channel.applicationCaller].toggleSMS = $event),
                    onChange: ($event: any) => (_ctx.enableMode($event, channel.id))
                  }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["id", "onUpdate:modelValue", "onChange"])), [
                    [_vModelCheckbox, _ctx.channelStatus[channel.applicationCaller].toggleSMS]
                  ])
                : _createCommentVNode("v-if", true),
              (channel.communicationMode == 'whatsapp')
                ? _withDirectives((_openBlock(), _createBlock("input", {
                    key: 2,
                    class: "form-check-input",
                    type: "checkbox",
                    id: channel.id,
                    "onUpdate:modelValue": ($event: any) => (_ctx.channelStatus[channel.applicationCaller].toggleWhatsapp = $event),
                    onChange: ($event: any) => (_ctx.enableMode($event, channel.id))
                  }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["id", "onUpdate:modelValue", "onChange"])), [
                    [_vModelCheckbox, _ctx.channelStatus[channel.applicationCaller].toggleWhatsapp]
                  ])
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["table-data", "table-header"])
      ])
    ]),
    _createCommentVNode(" <emailChannelConfiguration :item=\"item\" ref=\"passConfigureDataToEmailConfigureModal\" @callChannel=\"getConfigurations()\"></emailChannelConfiguration>\n  <smsChannelConfiguration :item=\"item\" ref=\"passConfigureDataTosmsConfigureModal\" @callChannel=\"getConfigurations()\"></smsChannelConfiguration> ")
  ]))
}